export const violationsDescriptionListHtml = `
<div syle="height:100%;"><%= violationsStat['summ'] %> нарушений. Меню для карты:</div>
<div id="violations-setting-list">
  <div class="text-height-0">
    <button type="button" class="btn btn-link py-0 violations-setting-list-checked-all" id="violations-setting-list-checked-all" >
        выбрать все
    </button>
    <button type="button" class="btn btn-link py-0 violations-setting-list-not-checked-all" id="violations-setting-list-not-checked-all" >
        снять все
    </button>
    <button type="button" class="btn btn-link py-0 violations-setting-list-show-drains-and-refueling" id="violations-setting-list-show-drains-and-refueling" data-is-show="1" >
        показать сливы, заправки
    </button>
  </div>
  
  <table>
    <tbody>
      <% 
        for (let i = 0; i < data.length; i++) {
            let violationId = data[i]['id'];
            let checked = violationId in violationsSetting ? 'checked' : '';
            let violationCnt = violationsStat[violationId] || 0;
            let boldClass = violationCnt ? ' class="font-weight-bold violations-setting-list-tr"' : ' class="violations-setting-list-tr"';
            let arrayData = data[i]['group_ids'];
      %>
        <tr <%= boldClass %>>
          <td>
            <input class="violations-setting-list-checkbox" type="checkbox" <%= checked %> id="<%= violationId %>" data-array="<%= JSON.stringify(arrayData) %>">
          </td>
          <td class="text-right">
            <label class="violations-setting-list-count" for="<%= violationId %>" style="width:100%">
              <%= violationCnt %>
            </label>
          </td>  
          <td>
            <label for="<%= violationId %>" class="violations-setting-list-label"> 
              <%= data[i].description %>
            </label>
          </td>  
        </tr>
      <% } %>
    </tbody>
  </table>
</div>
`;
