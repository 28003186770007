<template>
  <!-- logout: само окно-->

  <Transition name="fade">
    <div
      id="logout-window"
      :style="[{ display: props.show ? 'block' : 'none', overflow: 'hidden' }]"
    >
      <!-- Картинка крестика-->
      <img
        class="close"
        src="/images/close.png"
        @click="closeLogoutPageHandler"
      />

      <div class="full-height logout-window-content text-center">
        <table class="simple-table">
          <tr
            v-for="(text, key) in userInfo"
            :key="key"
          >
            <th
              class="text-left"
              v-html="text"
            ></th>
            <td
              style="text-align: left"
              v-html="getValueByKey(key)"
            ></td>
          </tr>
        </table>

        <div class="logout-page__button-list mt-2">
          <button
            id="logout-out-btn"
            class="btn btn-primary config-btn-primary btn-md"
            @click="showWindowHandler('logout-window')"
          >
            Выйти из аккаунта
          </button>

          <button
            v-if="projectData.senderRights.rule_token1c_create"
            id="logout-out-btn"
            class="btn btn-primary config-btn-primary btn-md"
            @click="generate1CToken()"
          >
            Сгенерировать 1С токен
          </button>

          <template v-if="projectData.isAdmin">
            <button
              id="logout-switch-btn"
              class="btn btn-primary config-btn-primary btn-md"
              @click="showWindowHandler('admin-modal')"
            >
              Перейти в панель администратора без выхода из аккаунта
            </button>
          </template>

          <button
            id="logout-switch-btn"
            class="btn btn-primary config-btn-primary btn-md"
            @click="emit('to-manual')"
          >
            Перейти в справочник
          </button>
        </div>
      </div>
    </div>
  </Transition>

  <Transition name="fade">
    <logout-window-confirm
      :show="showWindow === 'logout-window' && props.show"
      :login="projectData.login"
      @close="showWindow = ''"
      @logout="logoutHandler"
    />
  </Transition>

  <Transition name="fade">
    <admin-modal
      :show="showWindow === 'admin-modal' && props.show"
      @close="showWindow = ''"
      @to-monitoring="closeLogoutPageHandler"
      @logout="logoutHandler"
    />
  </Transition>
</template>

<script setup>
import { inject, ref, watch } from 'vue';

import LogoutWindowConfirm from './confirm/LogoutWindowConfirm.vue';
import AdminModal from '../Admin/AdminModal.vue';
import { getBaseHttpUrl_handler } from '../../helpers/getBaseHttpUrl_handler'
import axios from 'axios';
import { decodeMessage } from '@/App/use/encrypter';
import { formatTimeHelper2 } from '@/helpers/main_helper';

const props = defineProps({
  show: {
    type: Boolean,
    require: true,
  },
});

const emit = defineEmits(['close', 'logout', 'to-manual']);

const projectData = inject('projectData');

const userInfo = {
  login: 'Логин',
  validity_time_from: 'Срок&nbsp;действия,&nbsp;с',
  validity_time: 'Срок&nbsp;действия,&nbsp;до',
  templateTimeIntervals: 'Интервалы отчетов',
};

if (projectData.senderRights.rule_token1c_create) {
  userInfo.token1c = 'Токен&nbsp;1С';
  userInfo.token1c_time = 'Токен&nbsp;1С&nbsp;срок&nbsp;действия,&nbsp;до';
}

watch(
  () => projectData,
  (newData, oldData) => {
    if (newData.senderRights.rule_token1c_create) {
      userInfo.token1c = 'Токен&nbsp;1С';
      userInfo.token1c_time = 'Токен&nbsp;1С&nbsp;срок&nbsp;действия,&nbsp;до';
    } else {
      delete userInfo.token1c;
      delete userInfo.token1c_time;
    }
  },
  { deep: true },
);

const showWindow = ref('');

watch(
  () => props.show,
  (show) => {
    if (show) showWindow.value = '';
  },
);

const generate1CToken = async () => {
  const token = decodeMessage(localStorage.getItem('access_token'));
  const url = getBaseHttpUrl_handler()
  const res = await axios.post(`${
      url + process.env.VUE_APP_TEMPLATE_SERVICE_HTTP_PATH
    }token1c/generate`, {token}).catch(e => undefined)
  if (res?.status == 200) {
    if (res.data.success) {
      projectData.token1c = res.data.token1c;
      projectData.token1c_time = Number(res.data.token1c_time * 1000);
    }
  }
}

const showWindowHandler = (str) => {
  showWindow.value = str;
};

const closeLogoutPageHandler = () => {
  emit('close', '');
};
const logoutHandler = () => {
  showWindow.value = '';
  emit('logout');
};

const getValueByKey = (key) => {
  if (key === 'login') {
    return `<span class=\"login-text\">${projectData.login}</span>`;
  } else if (key === 'templateTimeIntervals') {
    return getStringByTimeIntervals(projectData[key]);
  } else if (key.indexOf('_time') === -1) {
    return projectData[key] ?? '?';
  } else {
    let value = projectData.senderRights[key] ?? '?';
    if (key === 'token1c_time') {
      value = projectData[key] ?? '?';
    }
    if (value !== '?') {
      return value ? getStringByUnixTime(value) : 'не задано';
    }
  }
};

const getStringByUnixTime = (unixtime) => {
  const d = new Date(unixtime);

  const year = d.getFullYear();
  let month = 1 + d.getMonth();
  let day = d.getDate();

  let hours = d.getHours();
  let minutes = d.getMinutes();

  month = rotateToStringLen2_helper(month);
  day = rotateToStringLen2_helper(day);
  hours = rotateToStringLen2_helper(hours);
  minutes = rotateToStringLen2_helper(minutes);

  const date = day + '.' + month + '.' + year + ' ';

  return date + hours + ':' + minutes;
};
const getStringByTimeIntervals = (intervals) => {
  if (intervals.length === 0) {
    return "Без ограничений";
  }

  let res = '';
  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    res += interval[0] ? formatTimeHelper2(new Date(interval[0]), false) : 'Без начала';
    res += ' - ';
    res += interval[1] ? formatTimeHelper2(new Date(interval[1]), false) : 'Без конца';
    res += ';\n';
  }

  return res;
}
</script>

<style lang="scss">
.logout-page__button-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 400px) {
    flex-direction: column;
    & button {
      margin-top: 20px;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    & button {
      margin-bottom: 10px;
    }
  }
}
#logout-window .logout-window-content {
  width: calc(85vw - 30px);
  overflow: auto;
}
</style>
