<template>
  <div
    class="admin-modal__form-window"
    style="display: block"
  >
    <!-- окно поверх других -->
    <div :id="`${getIsOrganizationObjectValue('idPart')}-redact-window`">
      {{ props.data.info }}
      <div class="row justify-content-center">
        <h5>
          <span class="header-form-description">
            <template v-if="props.create">Создание</template>
            <template v-else-if="props.edit">Изменение</template>
            <template v-else>Администрирование</template>
          </span>
          {{ getIsOrganizationObjectValue('partText') }}
        </h5>
      </div>
      <!-- Картинка крестика-->
      <img
        class="close"
        src="/images/close.png"
        @click="closeHandler"
        style="position: absolute; top: 5px; right: 15px; z-index: 1000"
      />
      <div class="col-12">
        <div class="my-overflow text-center">
          <form
            :name="getIsOrganizationObjectValue('idPart')"
            autocomplete="off"
            @submit.prevent="sendData"
            style="
              overflow: scroll;
              margin-top: 20px;
              height: calc(90vh - 60px);
            "
          >
            <template v-if="props.data.userId === '1'">
              <div class="input-group mb-0 text-height-normal user-select-none">
                <label class="mb-0">
                  <input
                    type="checkbox"
                    name="isChangeOwner"
                    v-model="isChangeOwner"
                  />
                  <span>Сменить владельца</span>
                </label>
              </div>

              <pre>{{ props.data.owner }}</pre>
              <div class="input-group mb-2">
                <input
                  type="checkbox"
                  :checked="
                    getIsOrganizationObjectValue('isOrganizationChecked')
                  "
                  name="isOrganization"
                  class="display-none"
                />
                <input
                  type="checkbox"
                  checked
                  name="updateMode"
                  class="display-none"
                />
                <input
                  type="text"
                  checked
                  name="timezone"
                  class="display-none"
                />
                <div class="input-group-prepend">
                  <label
                    class="input-group-text"
                    for="inputGroupSelect00"
                    title="Создатель"
                  >
                    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      enable-background="new 0 0 64 64"
                      xml:space="preserve"
                      alt="Создатель"
                    >
                      <image
                        id="image0"
                        width="20px"
                        height="20px"
                        x="0"
                        y="0"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                      AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABtlBMVEX///8AAAAmXncmXHYm
                      XXgmXXcUM0EXOUkZPk4bQ1QAAAApWnMmXXcXOUgfQlQnXngmXXcUMT4bQVIoXXcbQ1QiVXcmXXcV
                      NEEaPlgnXncZPEwrVYAmXXcVQFUoX3gcRVYmXXgbQFEmXngYPU0mXXcZPk4mXHcbQVMkX3UmXXcb
                      R1YmXXYXOkgmXHUmXXcaQ1MAgIAmXXgYO0sAQEAmXXcaP1ElXHYnXXcWNkUaQlQnXXYmXncXOUgb
                      RVUmXngmXXcbQlMmXngmXXcaQlQjXHgbRVgnXHYcQ1UmXnYYO0onYnYfR1wmXnYaRFQlXXgaQVIn
                      XHYZPU0nXXgWOUcmXXcmXXZUiKAYO0oaPk+35Ph3y/K34/m35fi35Ph/zvOP1fO34/eN1PS45Pe/
                      3/+25PiPz++G0PK34/eH0fP/2y7d4Izf04X/xC7/yCz/2yzJ4cesyaT/yCv/3in/3CzS13X0w0D/
                      wSz/2i3/vy3/3Cv/2y7/yS3/wC0mXXcTLzsTMDxXi6MiS14jS1235PhvyPFxyfHH4svM3r7T2Kio
                      x6TA04LVyWbkw1L/2y3/zS3/ti3/xS3////ibgsLAAAAfXRSTlMAAUl/s+b96MB9Ah+97jpi8v6e
                      R3oP4/wdntoG+QwzXGajk9GBwV6ZI/5Bteo9/WsCot0Ea6lFzfV3NLztXVf8kFH7iCRDJ0im4A0Z
                      NmFgnIrJwO9+qPThvuD7Lrj361RAcIQI4RDEgMA4arJkFyPn/UEfkOTkz6DcJKjK4ealzYUAAAAB
                      YktHRACIBR1IAAAACXBIWXMAAAG7AAABuwE67OPiAAAAB3RJTUUH5gYIFDgCpINZCwAAAaBJREFU
                      WMPt0lVTw0AYheHF3d29eIHi7u7uroXiLrsBWlx/Mk3bQBqb/cIFDPS93OQ809kUIfmcnF1c3dw9
                      PL28kap8fLE5Ys7PX80+IBBzAAkKhu9DMP4CCAmF7sPC7YGISCAQhe0BEg3bx8QKASYOBMRjIUAS
                      QECiGEgCAcliIAUEpIqBNBCgEQPpICAjUwRkgQCULQRyYHuUqxUAeUDg8xpVXSFbfgEf0BWCAYSK
                      Mj+BYhVzVFL69QvKysHzikr7O6iqhu012u/9kWpqxf9Epo5+X9+AxQBhGmn3TbFYCiBMMyVQgKUB
                      oqPbt9TKAUwrFdCG5QDSTrPv0MoDpJMC6MIKQDcF0KME9FIAfUpAPwXgqgR4UAADSsAgBTA0zGvk
                      8up6lNcYzXfkN240TUA3/xWYnJJp2mi6mZFplgfMGWUzyTb/64CFRYmWzJe4LNGKBLAK+QprfwrQ
                      s63f3t1vGNg2uVe2LA+2bx92LOe73PnePtvB49Pz4REberH2+mbNwL14bHtgOz/hzvW283fbAwfg
                      ABzATwGnZ9bOL6x9AIHMezNVdDwDAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA2LTA4VDE3OjU2
                      OjAyKzAzOjAw1Ry+wgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNi0wOFQxNzo1NjowMiswMzow
                      MKRBBn4AAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC"
                      />
                    </svg>
                  </label>
                </div>
                <select
                  class="custom-select"
                  id="inputGroupSelect00"
                  name="owner"
                  v-model="owner"
                  :disabled="!isChangeOwner"
                >
                  <option
                    v-if="!getInDataIndex.length"
                    :value="props.data.userId"
                    :data-login="props.data.userLogin"
                    selected
                  >
                    {{ props.data.userLogin }}
                  </option>
                  <option
                    v-for="owner in props.data.owners"
                    :value="owner['value']"
                    :data-login="owner['login']"
                    :key="owner.value"
                  >
                    {{ owner['login'] }}
                  </option>
                </select>
              </div>
            </template>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <label
                  class="input-group-text"
                  for="inputGroupSelect01"
                  title="Роль/группа пользователя"
                >
                  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    enable-background="new 0 0 20 20"
                    xml:space="preserve"
                  >
                    <image
                      id="image0"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAQAAAC0NkA6AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
                  RQfmBggUOAKkg1kLAAADR0lEQVRYw93XS2hcZRQH8F+SWrQPJamUtr6wlpZStcVFQRBBpdKSLhTR
                  LoxFBAVBXGiMYK2IdeXKx8qqCwVt4jotWEmkvsDHwi7sCyzaaW1j7UCdmNZ0muMi8TJ35t7JpJkR
                  9H9X53znnP/9n++b757h/47NhowqGdLdKopXRcWzozUqwnm9llrqOedFK9QMC72J1ScMNZ+kJCxJ
                  rCXCH7Mr2J7hC7QlVseUp8kk36EnsXrwfZN7hW7hvD7LLPO8v1qz8exo/RGeVDOk1Nof47+ANt0+
                  cNCosw7Y6e7mU6zxVWpHQthreTMp1isKv3jGavMstNY2I8JJt+RkbPCOQ0aNOmine6anWGxE6Dc/
                  5e20WyjorIlfaV+N6s+sqE+yS/hER41/ri+Ed6u8dyoKp7xgrfnmW2ebU8IZd+RT3KjsnGsz124y
                  btw1KRVFod+CVNxCA8KZfDUvCu/nvsJHQl+FvU/on7rjuh1XsAm0+VgYziuzV3ggl2SLsDuxNgin
                  EhUFIRxL1IyIyiNQeUGuVu8q/BZrEushvGk0M7LkramIDIwJ83JJ5gl/JtYh4dbE2qTgmI2JvU44
                  mF0mpvluVK6XRNWWV2KBUMpu10zQjonc1bnpypdKcoI6P7oVOD57ki+xOXf1PvIO8Uz25C7hV1dl
                  xnU6Ldw2e5I2w8JARifaDQqDjRSZfn2508KAK1MxXQaFkxY3h4T1fhNO2l5R8oTws5vrF9macQdD
                  h60ZL3GDPUL4KfmoRWowzMARIRypmLn+QU+yVotNysLnDXZDuwcdEi5WqelwUTjqCXNqchbbLxxO
                  PhDTkkwWLAqLUr5FQjGnjXuEC1amWt4AjoiKJFglHM6Jvt5R4evkjGWS1J7zM1Qp6ZryZuGYe427
                  3a56751N0lXVrnySDi+b66xXZk5Sq6SYk/+eh5Vt9M1sSa7G7zn5Hzpnjscq/s+0QMmn7jfucW/M
                  lqTenrDKZcKPkKenlqQ4I5KnvY4nvQ1ew9ilKam3J5drMzFV+CW9LuRNKWmsE35IefanJpNq9All
                  j3hKKNvSCAXXCYWUpyByhtdJbBfKJkx4tDGKyfmq9rmibk6fEJ5tlILJIS/9jE2bs922mVD8V/E3
                  mJtPFTzfx1UAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDYtMDhUMTc6NTY6MDIrMDM6MDDVHL7C
                  AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA2LTA4VDE3OjU2OjAyKzAzOjAwpEEGfgAAAABJRU5E
                  rkJggg=="
                    />
                  </svg>
                </label>
              </div>
              <select
                class="custom-select"
                id="inputGroupSelect01"
                name="role"
                v-model="userRole"
              >
                <option
                  v-if="props.data.main_admin"
                  value="main_admin"
                >
                  !!! Главный администратор !!!
                </option>
                <template v-if="props.data.main_admin || props.data.admin">
                  <option value="admin">!!! Администратор сервера !!!</option>
                  <option value="customer_admin">
                    ! Администратор клиента !
                  </option>
                </template>
                <option
                  value="user"
                  selected
                >
                  Пользователь
                </option>
              </select>
            </div>

            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <label
                  class="input-group-text"
                  for="inputGroupSelect01"
                  title="Контрагент"
                >
                  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    enable-background="new 0 0 20 20"
                    xml:space="preserve"
                  >
                    <image
                      id="image0"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAQAAAC0NkA6AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
                  RQfmBggUOAKkg1kLAAADR0lEQVRYw93XS2hcZRQH8F+SWrQPJamUtr6wlpZStcVFQRBBpdKSLhTR
                  LoxFBAVBXGiMYK2IdeXKx8qqCwVt4jotWEmkvsDHwi7sCyzaaW1j7UCdmNZ0muMi8TJ35t7JpJkR
                  9H9X53znnP/9n++b757h/47NhowqGdLdKopXRcWzozUqwnm9llrqOedFK9QMC72J1ScMNZ+kJCxJ
                  rCXCH7Mr2J7hC7QlVseUp8kk36EnsXrwfZN7hW7hvD7LLPO8v1qz8exo/RGeVDOk1Nof47+ANt0+
                  cNCosw7Y6e7mU6zxVWpHQthreTMp1isKv3jGavMstNY2I8JJt+RkbPCOQ0aNOmine6anWGxE6Dc/
                  5e20WyjorIlfaV+N6s+sqE+yS/hER41/ri+Ed6u8dyoKp7xgrfnmW2ebU8IZd+RT3KjsnGsz124y
                  btw1KRVFod+CVNxCA8KZfDUvCu/nvsJHQl+FvU/on7rjuh1XsAm0+VgYziuzV3ggl2SLsDuxNgin
                  EhUFIRxL1IyIyiNQeUGuVu8q/BZrEushvGk0M7LkramIDIwJ83JJ5gl/JtYh4dbE2qTgmI2JvU44
                  mF0mpvluVK6XRNWWV2KBUMpu10zQjonc1bnpypdKcoI6P7oVOD57ki+xOXf1PvIO8Uz25C7hV1dl
                  xnU6Ldw2e5I2w8JARifaDQqDjRSZfn2508KAK1MxXQaFkxY3h4T1fhNO2l5R8oTws5vrF9macQdD
                  h60ZL3GDPUL4KfmoRWowzMARIRypmLn+QU+yVotNysLnDXZDuwcdEi5WqelwUTjqCXNqchbbLxxO
                  PhDTkkwWLAqLUr5FQjGnjXuEC1amWt4AjoiKJFglHM6Jvt5R4evkjGWS1J7zM1Qp6ZryZuGYe427
                  3a56751N0lXVrnySDi+b66xXZk5Sq6SYk/+eh5Vt9M1sSa7G7zn5Hzpnjscq/s+0QMmn7jfucW/M
                  lqTenrDKZcKPkKenlqQ4I5KnvY4nvQ1ew9ilKam3J5drMzFV+CW9LuRNKWmsE35IefanJpNq9All
                  j3hKKNvSCAXXCYWUpyByhtdJbBfKJkx4tDGKyfmq9rmibk6fEJ5tlILJIS/9jE2bs922mVD8V/E3
                  mJtPFTzfx1UAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDYtMDhUMTc6NTY6MDIrMDM6MDDVHL7C
                  AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA2LTA4VDE3OjU2OjAyKzAzOjAwpEEGfgAAAABJRU5E
                  rkJggg=="
                    />
                  </svg>
                </label>
              </div>
              <ui-search-with-list
                v-model:selected="id_contractor"
                placeholder="Выберите контрагента"
                :data="contractorsSelectData"
                style="
                  position: relative;
                  -ms-flex: 1 1 auto;
                  flex: 1 1 auto;
                  width: 1%;
                  margin-bottom: 0;
                "
                :input-class="'form-control admin-search-with-list'"
              />
            </div>

            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="basic-addon01"
                  title="Используется для входа в систему"
                >
                  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    enable-background="new 0 0 20 20"
                    xml:space="preserve"
                  >
                    <image
                      id="image0"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAQAAACQ9RH5AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
                  RQfmBggUOAKkg1kLAAAB7ElEQVRYw+2XPUtjURCGnxgIWwiK4oJ6FWWtxGZtLRStXD9iK/4BETWl
                  LMIW+w8WLPzCwtJCVHT9A9pqZSMaFjamNCauKJpwLdxbaJJ7Z+I5iHLftwsn88xMzp3MhVChPpoi
                  FXynlThDtOEAKf6wyzZ/bSfazBJ53BcusEGbTewY10VQzzlGbWETFMpin+qetVOtP/YJbbxqx6fJ
                  zxveZBa8JsK6uKyYxLaWuMnlnMeRhKwSgceIipOMEjcHHlT155s5cIcK/EV12leyG+35WhJSNqtd
                  ZaKCqLJWW5AM/E8VM2cOnFaBRadl4KQKLDotA++pwLuq075qUYzMB9nIlGpVDF40iYVmciJslkaz
                  YBgUtLvAiGkswGzg6jNjAwsw6tPwLMO2sAAN/OKhRK3r2t+2koXeIc4Q7f8X+iR77JCyWW2oUO9D
                  uscpSjdf6aITh1pqgCuypDjhhCOOKZhP8DNTbJHxHZmXbDJJgylkjAn2S0yr8v/Hvxkn9jpoPfOk
                  VTu15wu+U1dppYmA1gY5wxyftNhekq+Cej6jRw6N8FOxYwU5zw/ZsxNhwRjU87IEPWMc6+IyHQw+
                  twI+LW7sS93qb6JAN1Q//6D4TeLYAlYUtV8xpaS+p1eS3QCH3BmD3nFAn5UuhgoV6o31COZ0s8/E
                  SGGyAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA2LTA4VDE3OjU2OjAyKzAzOjAw1Ry+wgAAACV0
                  RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNi0wOFQxNzo1NjowMiswMzowMKRBBn4AAAAASUVORK5CYII="
                    />
                  </svg>
                </span>
              </div>
              <input
                class="form-control my-add-input-login"
                placeholder="Логин"
                aria-label="Username"
                aria-describedby="basic-addon01"
                type="text"
                name="myLogin"
                @keyup="keyUpInputHandler"
                @keydown="keyDownInputHandler"
                v-model="userLogin"
              />
            </div>

            <div class="input-group mb-0">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="basic-addon02"
                  title="Используется для входа в систему"
                >
                  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    enable-background="new 0 0 20 20"
                    xml:space="preserve"
                  >
                    <image
                      id="image0"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAQAAACQ9RH5AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
                RQfmBggUOAKkg1kLAAAB10lEQVRYw+2XTS/EQBjHf+uKChcOmiCSXoW4kGwcJC5e4mUT34L4ToIl
                xEUi4erMRRaJiHURL7F1IUQdjEn3xc50TBM2859D22f+fX7TmcnTFpwaXRmDewaZIUsXPiGnXHDM
                NndpD3OBAlFVe2OPkfSwvRzVgH63D1bpTAM7ym0d7Fe7YcA2NsurTP/CGosENNNMwCLrsb4nhu1O
                8p1MvUFPjf687C/am/CMXNt3ln50LfMuXAe2wDn5NEt1fSvSN2nneQtyklXaFM4To/pQoSGR7JU+
                pbeHF+EeUFmblMmmxXGbS6X3il1xNvV7cFYcd5XOuGtMy11X52Ly+rXcgXCf/R78LFK1aLlbhPtZ
                ZVTvvkjbmcivXuOU5MAAPluEsvx9S/VK/MkfskOgMxSfB22IbnvEV4O3rGMjIvKVmOpNH9KawpKG
                tKnAkWaqpKog/ald7cAO3NjgIgt4eMxWfFMkjWsoXuiu6ZDxdq6N45FOWYqb58t6csbxxODyqu0Z
                x2uAk2yujKW4Bni87GrCOK6l+PQUYpulg6JxPPEaRxTJ4eExJz/szeJVYPc+dmAHbkSw8pfaSCU1
                +DAV8L7aEvBo/c/pnm6d0fnkKVmDltjQwzo5/Xd9AowT4BAFHQfpAAAAJXRFWHRkYXRlOmNyZWF0
                ZQAyMDIyLTA2LTA4VDE3OjU2OjAyKzAzOjAw1Ry+wgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0w
                Ni0wOFQxNzo1NjowMiswMzowMKRBBn4AAAAASUVORK5CYII="
                    />
                  </svg>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Пароль"
                aria-label="Password"
                aria-describedby="basic-addon02"
                name="password"
                v-model="userPassword"
              />
            </div>
            <span
              class="my-add-input-login-info mb-3"
              style="
                display: block;
                text-align: left;
                margin-bottom: 1rem !important;
              "
              :style="{ color: loginInfo.color }"
            >
              {{ loginInfo.text }}
            </span>

            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="basic-addon03"
                  title="Определяет список доступных объектов, перечислять через запятую. Например: xm01,!XM01_GHN 48103H предоставит доступ ко всем объектам, наичнающимся на xm01, кроме XM01_GHN 48103H"
                >
                  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    enable-background="new 0 0 20 20"
                    xml:space="preserve"
                  >
                    <image
                      id="image0"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAQAAAC0NkA6AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
                  RQfmBggUOAKkg1kLAAADeUlEQVRYw+3XS2xVRRzH8U8P7W1JbzUgCYnSajRBqEYSXIgI0fhKLPIq
                  tRAxLgRfO+3OnTsQpQaxC7dWm2hMtBraGBM3JmgksFGeLW3TVnTR+KAXChF6XND7OL3nnF5N2Rhm
                  Nnf+8/vOb+Y/c87cw41yoxRKxisO+13OER0WpmoX6nBEzh8Oe1lNpRa3+lFYUo9rStQ2+Tmi/d7S
                  clFVTOQNk0ZMqNNkje0WOeF+l2Is6hzV7LJe/QZdtkSTal3/PnW1OuR0xPa95qoDbplriLiVPGyD
                  uy1x1YBeX5l2pzc9H0Mfstd3WKfNKvUmDPlavyvppiv8EMlx6CerkU3YERp9M4s46cF0i4lZQCiU
                  szGRWO2XGGLK+uTUHY0BQqG/PR1LrPJnAjGmPt6kJQEIhS5Yo9qzPnfasH6vqnd77CryNf6o6E5B
                  QhOORdojTqfqj8dZ1CQu/b/We/NDBwWTdW42v+XxcpOWebbgifLQ8XlOViinNmpxx7xbhEKPRNP1
                  5Lwnq2RX8iaPXReTyNSr/HZd0nXF4uJK7rMUYw5ocZesrJVadBmveM5j3veUlbKylmtx0CgWeLQo
                  ed2YF2Kuzmovpr46rtVxuy0oY2vsNuqDYmCXxai1wydGXJRzSo92GTT4MtWiVwMy2vU4JeeCIT22
                  q8Ei70adW42UDTCoFYGDiRbvCdDqbFnPiE0il2KVzsRh3hYICqs55DbL9BVWEQjsS2T3lprsT03I
                  PtzknFBoGWic2YsGUixCoT15i81zbu0WvFRmsgutc7Ibrp2gs3MKB2RUGxfq06hRv9CoBTIVsIOq
                  0+/DYn0GXZHIQbRXxG4N7KzoYduMvkikD1sqYncwVNFsTmFFJLKcOS7gfB3i0szPtdalCM+jIRJp
                  wPlCa62HEtmLRWE2As2uF1AXidRhstBKo88Hfp3J3Ep8m/ICJNBj1LRpo3oEOF3oT6PP8fGM3340
                  +ythNt2x+IeF/jS6u3gML2pGsy9ipdtiTTaW5D2ZbiNjcKYx7J4ZePYRGEr4ggoKbCkdPQJnrrFb
                  C4EpnR6QtX6WSfLzsK1EladLpzhtU176Vuo5fyf1UetMZfcUhUGKzf6YD6VoyroSyGl7S/48gi0G
                  ymRjFb442o2XsWdsjpPWaPORkyZNGfap52b/A0wpGTt9ZtiUSSd0a6v8Y/tG+d+WfwAgnuVKB3BT
                  9QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNi0wOFQxNzo1NjowMiswMzowMNUcvsIAAAAldEVY
                  dGRhdGU6bW9kaWZ5ADIwMjItMDYtMDhUMTc6NTY6MDIrMDM6MDCkQQZ+AAAAAElFTkSuQmCC"
                    />
                  </svg>
                </span>
              </div>
              <textarea
                class="form-control"
                placeholder="Маска объектов"
                aria-describedby="basic-addon03"
                name="objects_mask"
                v-model="objectsMask"
              ></textarea>
            </div>
            <!-- <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon04" 
                title="Определяет список доступных отчетов, перечислять через запятую точные наименования или *. Например:
'Работа объекта, CAN'
предоставит доступ к отчетам 'Работа объекта' и 'CAN', но не к отчетам 'Работа объекта посуточно' или 'CAN показатели'">
                <img width="20px" src="/svg/admin_panel/icons8-binoculars-50.png" alt="M">
              </span>
            </div>
            <textarea class="form-control" placeholder="Названия отчетов" aria-describedby="basic-addon04" name="templates_names"></textarea>
          </div> -->
            <div class="input-group mb-3 my-input-group user-select-none">
              <span class="input-group-text w-100 my-input-group-text">
                Шаблоны отчетов
              </span>
              <label>
                <input
                  type="checkbox"
                  value="template_all"
                  v-model="checkedTemplates"
                />
                <span class="label-text"> ВСЕ доступные, включая новые </span>
              </label>
              <label
                v-for="(text, name) in props.data.senderRights[
                  'templates_names_available_arr'
                ]"
                :key="name"
              >
                <input
                  type="checkbox"
                  :value="`template_${name}`"
                  v-model="checkedTemplates"
                />
                <span class="label-text">{{ text }}</span>
              </label>
            </div>

            <div class="input-group mb-3 my-input-group user-select-none">
              <span class="input-group-text w-100 my-input-group-text">
                Промежутки отчетов
              </span>
              <ui-date-interval-select
                :dateRanges="dateRanges"
              />
            </div>

            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="basic-addon05"
                  :title="`Например, наименование ${getIsOrganizationObjectValue(
                    'partText',
                  )}`"
                >
                  Имя&nbsp;&nbsp;</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Наименование"
                aria-label="Title"
                aria-describedby="basic-addon05"
                name="title"
                v-model="title"
              />
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="basic-addon06"
                  title="Прочая справочная информация"
                >
                  Инфо
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Описание"
                aria-label="Description"
                aria-describedby="basic-addon06"
                name="description"
                v-model="description"
              />
            </div>

            <div class="input-group mb-3 my-input-group user-select-none">
              <span class="input-group-text w-100 my-input-group-text">
                Права доступа
              </span>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_geofences_watch']"
                  value="rule_geofences_watch"
                />
                <span class="label-text">Просмотр геозон</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_geofences_create']"
                  value="rule_geofences_create"
                />
                <span class="label-text">Создание геозон</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_geofences_update']"
                  value="rule_geofences_update"
                />
                <span class="label-text">Редактирование геозон</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_geofences_delete']"
                  value="rule_geofences_delete"
                />
                <span class="label-text">Удаление геозон</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="
                    !props.data.senderRights['rule_organizations_create']
                  "
                  value="rule_organizations_create"
                />
                <span class="label-text">Создание организаций</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_fuel_watch']"
                  value="rule_fuel_watch"
                />
                <span class="label-text">Просмотр топлива</span>
              </label>
            </div>

            <div class="input-group mb-3 my-input-group user-select-none">
              <span class="input-group-text w-100 my-input-group-text">
                Права доступа (справочник)
              </span>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_contractor_watch']"
                  value="rule_contractor_watch"
                />
                <span class="label-text">Просмотр контрагентов</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_contractor_create']"
                  value="rule_contractor_create"
                />
                <span class="label-text">Создание контрагентов</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_contractor_edit']"
                  value="rule_contractor_edit"
                />
                <span class="label-text">Изменение контрагентов</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_contractor_delete']"
                  value="rule_contractor_delete"
                />
                <span class="label-text">Удаление контрагентов</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_vehicle_watch']"
                  value="rule_vehicle_watch"
                />
                <span class="label-text">Просмотр транспортных средств</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_vehicle_create']"
                  value="rule_vehicle_create"
                />
                <span class="label-text">Создание транспортных средств</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_vehicle_edit']"
                  value="rule_vehicle_edit"
                />
                <span class="label-text">Изменение транспортных средств</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_vehicle_delete']"
                  value="rule_vehicle_delete"
                />
                <span class="label-text">Удаление транспортных средств</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_contract_watch']"
                  value="rule_contract_watch"
                />
                <span class="label-text">Просмотр договоров</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_contract_create']"
                  value="rule_contract_create"
                />
                <span class="label-text">Создание договоров</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_contract_edit']"
                  value="rule_contract_edit"
                />
                <span class="label-text">Изменение договоров</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_contract_delete']"
                  value="rule_contract_delete"
                />
                <span class="label-text">Удаление договоров</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  v-model="accessRights"
                  :disabled="!props.data.senderRights['rule_token1c_create']"
                  value="rule_token1c_create"
                />
                <span class="label-text">Создание токена для 1С</span>
              </label>
            </div>

            <div class="input-group mb-3 my-input-group user-select-none">
              <div
                class="input-group-text w-100 my-input-group-text d-flex justify-content-between"
                style="white-space: inherit; text-align: left"
              >
                IP-aдреса (если разрешены все оставьте этот раздел без
                изменений)

                <button
                  type="button"
                  class="btn btn-sm btn-primary config-btn-primary"
                  @click="() => togglePopup('ip')"
                >
                  Добавить
                </button>
              </div>

              <div class="row w-100">
                <div class="col-sm w-50 padding-none">
                  <div class="font-weight-bold border">Разрешенные</div>
                  <template
                    v-for="obj of ips"
                    :key="obj.ip"
                  >
                    <div
                      v-if="!obj.locked"
                      class="row align-items-center justify-content-between border"
                    >
                      {{ obj.ip }}
                      <div class="d-flex">
                        <div
                          class="btn btn-sm btn-outline-secondary padding-none"
                          style="padding: 2px"
                          @click="() => editIp(obj.ip, 'unlocked')"
                        >
                          <img
                            width="20"
                            height="20"
                            src="/images/manual/edit.svg"
                          />
                        </div>
                        <div
                          class="btn btn-sm btn-outline-secondary padding-none ml-2"
                          style="padding: 2px"
                          @click="() => deleteIp(obj.ip, 'unlocked')"
                        >
                          <img
                            width="20"
                            height="20"
                            src="/images/manual/delete.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-sm w-50 padding-none">
                  <div class="font-weight-bold border">Запрещенные</div>
                  <template
                    v-for="obj of ips"
                    :key="obj.ip"
                  >
                    <div
                      v-if="obj.locked"
                      class="row align-items-center justify-content-between border"
                    >
                      {{ obj.ip }}
                      <div class="d-flex">
                        <div
                          class="btn btn-sm btn-outline-secondary padding-none"
                          style="padding: 2px"
                          @click="() => editIp(obj.ip, 'locked')"
                        >
                          <img
                            width="20"
                            height="20"
                            src="/images/manual/edit.svg"
                          />
                        </div>
                        <div
                          class="btn btn-sm btn-outline-secondary padding-none ml-2"
                          style="padding: 2px"
                          @click="() => deleteIp(obj.ip, 'locked')"
                        >
                          <img
                            width="20"
                            height="20"
                            src="/images/manual/delete.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="basic-addon07"
                  title="* - нет ограничений"
                >
                  Действует с - до
                </span>
              </div>

              <ui-datepicker
                menuClassName="admin-form__datepicker"
                :autoPosition="false"
                :altPosition="
                  () => {
                    return {
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50% ,-50%)',
                    };
                  }
                "
                v-model:date="validityTimeFrom"
                :time-picker-component="DpTimePickerAccuracyOneMinute"
                :disabled="!!infinityTime"
                style="width: calc(100% - 181px); margin-right: 5px"
              />
              <button
                :class="[{ button_active: infinityTime }]"
                @click.prevent="toggleInfinity"
              >
                <svg
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.63636 16C2.90909 16 2 14 2 12C2 10 2.90909 8 5.63636 8C9.27273 8 14.7273 16 18.3636 16C21.0909 16 22 14 22 12C22 10 21.0909 8 18.3636 8C14.7273 8 9.27273 16 5.63636 16Z"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </button>
            </div>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <label
                  class="input-group-text"
                  for="inputGroupSelect02"
                  title="Можно отключить пользователя"
                >
                  Активен&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
              </div>
              <select
                class="custom-select"
                id="inputGroupSelect02"
                name="active"
                v-model="isActive"
              >
                <option
                  value="yes"
                  :selected="props.data.post['active'] === 'yes'"
                >
                  да
                </option>
                <option
                  value="no"
                  :selected="props.data.post['active'] === 'no'"
                >
                  нет
                </option>
                <option
                  value="block"
                  :selected="props.data.post['active'] === 'block'"
                >
                  заблокирован
                </option>
              </select>
            </div>

            <span data-info>
              <template v-if="isRequest">
                <span :style="{ color: COLORS.success }"
                  >Запрос отправлен ...</span
                >
              </template>

              <template v-else-if="success">
                {{
                  props.isOrganization
                    ? `Организация успешно${
                        props.edit ? ' изменена' : ' создана'
                      }`
                    : `Пользователь успешно${
                        props.edit ? ' изменен' : ' создан'
                      }`
                }}
              </template>

              <template v-else-if="error === ''">
                Режим
                {{
                  props.create
                    ? 'создания'
                    : props.edit
                    ? 'изменения'
                    : 'администрирование'
                }}
                {{ getIsOrganizationObjectValue('partText') }}
              </template>

              <span
                v-else
                :style="{ color: COLORS.error }"
                >{{ error }}</span
              >
            </span>

            <div
              class="row justify-content-center"
              :style="[{ marginBottom: bottomArea }]"
            >
              <input
                class="btn btn-primary config-btn-primary"
                type="submit"
                :value="
                  (props.create && 'Создать') || (props.edit && 'Изменить')
                "
                title="Отправляет изменения на сервер"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <ui-popup
      v-if="popups.ip"
      @close="togglePopup('ip')"
      close-button
    >
      <h5 class="text-center">Добавление IP-адреса</h5>

      <form
        class="row w-100"
        @submit.prevent="saveIp"
      >
        <label
          for="ip-locked"
          class="w-100"
        >
          Вид IP
          <select
            class="custom-select"
            id="ip-locked"
            name="isLocked"
            v-model="ipPopup.locked"
          >
            <option value="unlocked">Разрешен</option>
            <option value="locked">Запрещен</option>
          </select>
        </label>

        <label
          for="ip"
          class="mt-2 w-100"
        >
          IP-адрес
          <input
            id="ip"
            type="ip"
            pattern="^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$"
            class="form-control"
            placeholder="255.255.255.255"
            required
            v-model="ipPopup.value"
          />
        </label>

        <div class="d-flex align-items-center justify-content-center w-100">
          <button
            class="btn btn-sm btn-primary config-btn-primary mt-2"
            type="submit"
          >
            Добавить
          </button>
        </div>
      </form>
    </ui-popup>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from '@vue/runtime-core';
import fetchJson from '../../../src/dataRequest/fetch-json';

import UiDatepicker from '@/App/Monitoring/Sidebar/components/UiDatepicker.vue';
import DpTimePickerAccuracyOneMinute from '@/App/Components/DpTimePickerAccuracyOneMinute.vue';
import UiSearchWithList from '@/App/Components/UiSearchWithList.vue';
import UiDateIntervalSelect from '@/App/Admin/modals/UiDateIntervalSelect.vue';
import UiPopup from '@/ManualApp/Components/Popups/UiPopup.vue';
import { flatArrayToPairs } from '@/helpers/main_helper'

import { usePopup } from '@/ManualApp/use/popup';

const props = defineProps({
  data: {
    type: Object,
    require: true,
  },
  contractors: {
    type: Array,
    require: true,
  },
  create: {
    type: Boolean,
    require: false,
    default: false,
  },
  edit: {
    type: Boolean,
    require: false,
    default: false,
  },
  isOrganization: {
    type: Boolean,
    require: false,
    default: false,
  },
  action: {
    type: String,
    require: true,
    default: 'create',
  },
});

const emit = defineEmits(['close', 'refresh', 'formInfo']);

const { popups, togglePopup } = usePopup({ ip: false });

const COLORS = {
  success: 'green',
  error: 'red',
  default: 'blue',
  info: '#BF0003',
};

const ipPopup = ref({
  update: false,
  oldIp: '',
  locked: 'unlocked',
  value: '',
});

const isRequest = ref(false);
const loginInfo = ref({
  text: 'Начните вводить логин',
  color: 'black',
});
const error = ref('');

const bottomArea = ref('0px');
const isChangeOwner = ref(false);
const owner = ref(isEdit(props.data.post['owner'], props.data.userId));
const id_contractor = ref(isEdit(props.data.post['id_contractor'], '0'));
const contractorsSelectData = ref([]);
const userRole = ref(isEdit(props.data.post['userRole'], 'user'));
const userLogin = ref(isEdit(props.data.post['login'], ''));
const userPassword = ref(isEdit(props.data.post['psw'], ''));
const objectsMask = ref(isEdit(props.data.post['objects_mask'], ''));
const checkedTemplates = ref(
  isEdit(templatesStringToArray(props.data.post['templates_names']), []),
);
const dateRanges = ref(
  isEdit(flatArrayToPairs(props.data.post['template_time_intervals']), []),
);
const title = ref(isEdit(props.data.post['name'], ''));
const description = ref(isEdit(props.data.post['description'], ''));
const accessRights = ref(isEdit(getAccessRights(), []));

const rule_contract_create = ref();
const rule_contract_delete = ref();
const rule_contract_edit = ref();
const rule_contract_watch = ref();
const rule_contractor_create = ref();
const rule_contractor_delete = ref();
const rule_contractor_edit = ref();
const rule_contractor_watch = ref();
const rule_vehicle_create = ref();
const rule_vehicle_delete = ref();
const rule_vehicle_edit = ref();
const rule_vehicle_watch = ref();
const rule_token1c_create = ref();

const validityTimeFrom = ref([]);

const ips = ref([]);

const infinityTime = ref(true);
const isActive = ref(isEdit(props.data.post['active'], 'yes'));
const success = ref(false);

onMounted(() => {
  setContractorsSelectData();
  initDatePicker();
});

function clearDatePicker() {
  validityTimeFrom.value = [];
}

function setDefaultIntervalToDatePicker() {
  const { validity_time_from: timeBegin = 0, validity_time: timeEnd = 0 } =
    props.data.post || {};

  const d = new Date();

  let from;
  let to;
  if (+timeBegin > 0 && +timeEnd > 0) {
    from = new Date(+timeBegin * 1000);
    to = new Date(+timeEnd * 1000);
  } else {
    from = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
    to = new Date(d.getFullYear(), d.getMonth() + 1, d.getDate(), 0, 0, 0);
  }

  validityTimeFrom.value = [from.toString(), to.toString()];
}

function initDatePicker() {
  const { validity_time_from: timeBegin = 0, validity_time: timeEnd = 0 } =
    props.data.post || {};

  if (+timeBegin > 0 && +timeEnd > 0) {
    const from = new Date(+timeBegin * 1000);
    const to = new Date(+timeEnd * 1000);
    validityTimeFrom.value = [from.toString(), to.toString()];
    infinityTime.value = false;
  } else {
    validityTimeFrom.value = [];
    infinityTime.value = true;
  }
}

function saveIp() {
  if (ipPopup.value.update) {
    deleteIp(ipPopup.value.oldIp);
  }

  ips.value.push({
    ip: ipPopup.value.value,
    locked: ipPopup.value.locked === 'locked',
  });

  ipPopup.value = {
    update: false,
    oldIp: '',
    locked: 'unlocked',
    value: '',
  };
}

function editIp(ip, locked) {
  ipPopup.value.update = true;
  ipPopup.value.oldIp = ip;
  ipPopup.value.locked = locked;
  ipPopup.value.value = ip;
  togglePopup('ip');
}

function deleteIp(ip, locked) {
  ips.value = ips.value.filter((obj) => obj.ip !== ip);
}

const setContractorsSelectData = () => {
  contractorsSelectData.value = props.contractors.reduce(
    (acc, contractor) => {
      acc.push({ value: contractor.id, text: contractor.name });
      return acc;
    },
    [{ value: '0', text: 'Не выбрано' }],
  );
};

const getInDataIndex = () => {
  const organizationData = props.data.organizationsData.find((obj) => {
    return props.data.userLogin === obj.login;
  });

  return organizationData;
};

const getIsOrganizationObjectValue = (str) => {
  if (!str) return '';

  if (str === 'partText') {
    if (props.isOrganization) return ' организации';

    return ' пользователя';
  }
  if (str === 'idPart') {
    if (props.isOrganization) return 'organization';

    return 'user';
  }
  if (str === 'isOrganizationChecked') {
    if (props.isOrganization) return 'checked';

    return '';
  }
};

let typingTimer;
const keyUpInputHandler = () => {
  clearTimeout(typingTimer);
  typingTimer = setTimeout(checkLogin, 500);
};
const keyDownInputHandler = () => {
  clearTimeout(typingTimer);
};

const checkLogin = () => {
  loginInfo.value.text = `Логин проверяется`;
  loginInfo.value.color = COLORS.default;

  const uLogin = userLogin.value; // если пользователь введет еще что нибудь, то выведется логин из запроса а не новый логин
  const fd = new FormData();
  fd.append('login', uLogin);

  fetchJson(
    `${
      process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
    }Api/admin/checkUserExists`,
    {
      method: 'POST',
      body: fd,
    },
  )
    .then((data) => {
      if (!data) {
        loginInfo.value.text = `Ошибка проверки (НЕ JSON)`;
        loginInfo.value.color = COLORS.info;
      } else if (data.exists === 'yes') {
        loginInfo.value.text = `Логин "${uLogin}" занят`;
        loginInfo.value.color = COLORS.error;
      } else if (data.exists === 'no') {
        loginInfo.value.text = `Логин "${uLogin}" свободен`;
        loginInfo.value.color = COLORS.success;
      } else if (data.info) {
        loginInfo.value.color = COLORS.info;
        loginInfo.value.text = data.info;
      } else {
        loginInfo.value.color = COLORS.info;
        loginInfo.value.text = 'Неизвестная ошибка';
      }
    })
    .catch((rej) => {
      loginInfo.value.text = `Произошла ошибка: ${rej}`;
      loginInfo.value.color = COLORS.info;
    });
};

const toggleInfinity = () => {
  infinityTime.value = !infinityTime.value;

  if (infinityTime.value) {
    clearDatePicker();
  } else {
    setDefaultIntervalToDatePicker();
  }
};

const sendData = () => {
  if (!userLogin.value) {
    error.value = 'Логин не может быть пустым';
    return;
  }
  if (loginInfo.value.color === COLORS.error) {
    error.value = 'Поле логин имеет не корректное значение';
    return;
  }
  if (!userPassword.value) {
    error.value = 'Пароль не может быть пустым';
    return;
  }
  if (!objectsMask.value) {
    error.value = 'Маска объектов не может быть пустой';
    return;
  }
  if (!id_contractor.value) {
    error.value = 'Необходимо выбрать контрагента';
    return;
  }
  if (props.isOrganization && !title.value) {
    error.value = 'Поле имя не может быть пустым';
    return;
  }

  const fd = new FormData();

  if (props.edit) {
    fd.append('updateMode', 'on');
  }

  fd.append('timezone', new Date().getTimezoneOffset() / -60);
  if (isChangeOwner.value) {
    fd.append('isChangeOwner', isChangeOwner.value);
  }
  fd.append('owner', owner.value);
  fd.append('id_contractor', id_contractor.value);
  fd.append('role', userRole.value);
  fd.append('myLogin', userLogin.value);
  fd.append('password', userPassword.value);
  fd.append('objects_mask', objectsMask.value);
  fd.append('template_time_intervals', flattenDateRanges(dateRanges.value))

  checkedTemplates.value.map((name) => {
    fd.append(name, 'on');
  });

  fd.append('title', title.value);
  fd.append('description', description.value);

  accessRights.value.map((name) => {
    fd.append(name, 'on');
  });

  if (validityTimeFrom.value) {
    if (
      infinityTime.value.length ||
      !validityTimeFrom.value ||
      !validityTimeFrom.value.length
    ) {
      fd.append('validity_time_from', '');
      fd.append('validity_time', '');
    } else {
      fd.append(
        'validity_time_from',
        new Date(validityTimeFrom.value[0]).getTime() / 1000,
      );
      fd.append(
        'validity_time',
        new Date(validityTimeFrom.value[1]).getTime() / 1000,
      );
    }
  } else {
    error.value = `Время действия ${
      props.isOrganization ? 'организации' : 'пользователя'
    } задано не верно`;
    return;
  }
  fd.append('active', isActive.value);

  if (props.isOrganization || (props.edit && props.data.post.isOrganization)) {
    fd.append('isOrganization', 'on');
  }

  isRequest.value = true;

  fetchJson(
    `${
      process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
    }Api/users/administration`,
    {
      method: 'POST',
      body: fd,
    },
  )
    .then((data) => {
      isRequest.value = false;
      if (data.error) {
        error.value = data.error;
        return;
      }
      emit('formInfo', data.info);
      success.value = true;
    })
    .catch((rej) => {
      success.value = false;
      isRequest.value = true;
      console.error(rej);
    });
};
const closeHandler = () => {
  emit('close');
  emit('refresh');
  cleanState();
};

const cleanState = () => {
  isChangeOwner.value = null;
  owner.value = props.data.userId;
  userRole.value = 'user';
  userLogin.value = props.data.post['login'];
  userPassword.value = props.data.post['psw'];
  objectsMask.value = '';
  checkedTemplates.value = [];
  title.value = props.data.post['name'];
  description.value = props.data.post['description'];
  accessRights.value = [];
  validityTimeFrom.value = [
    new Date(props.data.post['validity_time']),
    new Date(props.data.post['validity_time_from']),
  ];
  isActive.value = 'yes';
  success.value = false;

  loginInfo.value = {
    text: 'Начните вводить логин',
    color: 'black',
  };
  error.value = '';
};

function isEdit(editValue, notEdit) {
  return props.edit ? editValue : notEdit;
}

function getAccessRights() {
  const rules = [
    'rule_fuel_watch',
    'rule_geofences_create',
    'rule_geofences_delete',
    'rule_geofences_update',
    'rule_geofences_watch',
    'rule_organizations_create',

    'rule_contract_create',
    'rule_contract_delete',
    'rule_contract_edit',
    'rule_contract_watch',
    'rule_contractor_create',
    'rule_contractor_delete',
    'rule_contractor_edit',
    'rule_contractor_watch',
    'rule_vehicle_create',
    'rule_vehicle_delete',
    'rule_vehicle_edit',
    'rule_vehicle_watch',
    'rule_token1c_create',
  ];
  return rules.reduce((accum, name) => {
    if (props.data.post[name] === 't') accum.push(name);
    return accum;
  }, []);
}

function templatesStringToArray(str) {
  const templates = {
    'Навыки МАН суммарный': 'template_skillsMan',
    'Рейсы по геозонам': 'template_regularMovingByGeo',
    'Превышение скорости': 'template_overspeed',
    'Эксплуатация экскаватора': 'template_specMachine',
    'Работа эвакуатора': 'template_evacuatorMoving',
    'Критические нарушения': 'template_criticalViolations',
    'Навыки МАН детализация': 'template_skillsManDetil',
    'CAN показатели': 'template_canIndicators',
    'Работа сцепления': 'template_clutchWork',
    'Работа ТС посуточно': 'template_shiftWork',
    'Статистика по организациям': 'template_clientsMonthly',
    'Заправки и сливы (баки)': 'template_fuelInOut_consumption',
    'Заправки и сливы (цистерны)': 'template_fuelInOut_cistern',
    'Диагностика': 'template_dignostic',
    'Диагностика (подробный)': 'template_diagnosticDetail',
    'Давление масла по парку ТС': 'template_oilPressure',
    'Моточасы работы ДВС по температуре масла ДВС': 'template_engineOilTemp',
    'XML Грубые нарушения': 'template_rudeViolationsXML',
  };
  if (!str) return [];
  if (str === '*') return ['template_all'];
  if (str !== '') {
    const translateTamplates = str.split(',');
    return translateTamplates.reduce((accum, name) => {
      accum.push(templates[name]);
      return accum;
    }, []);
  }
  return [];
}

function flattenDateRanges(data) {
  return data.reduce((acc, [start, end]) => {
    acc.push(start, end);
    return acc;
  }, []);
}

</script>

<style lang="scss">
.admin-modal__form-window {
  /* min-width: 400px; */
  width: 95vw;
  /* min-height: 450px; */
  height: 95vh;
  margin: 25px auto;
  display: none;
  background: #fff;
  padding: 16px;
  z-index: 1003;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  @media (max-width: 450px) {
    padding: 0;
    padding-top: 10px;
  }
}
.admin-search-with-list {
  &::placeholder {
    color: #000;
  }
}
.button_active {
  border: 2px solid lime;
}
.admin-form__datepicker {
  & .dp__arrow_top {
    display: none;
  }
}
</style>
