<template>
  <div>
    <ul>
      <li v-for="(range, index) in props.dateRanges" :key="index">
        <span v-if="range[0]">{{ formatDate(range[0]) }}</span>
        <span v-else>Без начала</span>
        -
        <span v-if="range[1]">{{ formatDate(range[1]) }}</span>
        <span v-else>Без конца</span>
        <button 
          class="btn btn-sm btn-danger"
          @click="removeDateRange(index)"
        >Удалить
        </button>
      </li>
      <span v-if="props.dateRanges.length == 0">Список отсутсвует</span>
    </ul>
    <div>
      <label>
        <input type="checkbox" v-model="noStart">
        Без начала
      </label>
      <span>
        <ui-datepicker
          menuClassName="admin-form__datepicker"
          :autoPosition="false"
          :altPosition="
                  () => {
                    return {
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50% ,-50%)',
                    };
                  }
                "
          v-model:date="validityTimeFrom"
          :time-picker-component="DpTimePickerAccuracyOneMinute"
          :disabled="false"
          style="width: 400px"
        />
      </span>
      <label>
        <input type="checkbox" v-model="noEnd"> 
        Без конца
      </label>
    </div>
    <button 
      class="btn btn-sm btn-primary config-btn-primary"
      @click="addDateRange">Добавить промежуток
    </button>
  </div>
</template>

<script setup>
import DpTimePickerAccuracyOneMinute from '@/App/Components/DpTimePickerAccuracyOneMinute.vue';
import UiDatepicker from '@/App/Monitoring/Sidebar/components/UiDatepicker.vue';
import { onMounted, ref, watch } from '@vue/runtime-core';
import { formatTimeHelper2 } from '@/helpers/main_helper';

const props = defineProps({
  dateRanges: {
    type: Array,
    require: true,
  },
});
const initDate = new Date()
initDate.setMilliseconds(0)
initDate.setSeconds(0)
initDate.setMinutes(0)
initDate.setHours(0)

const noStart = ref(false)
const noEnd = ref(false)
const validityTimeFrom = ref([initDate, initDate])

function formatDate(date) {
  return formatTimeHelper2(new Date(date), false)
}
function addDateRange() {
  const start = noStart.value ? null : validityTimeFrom.value[0].getTime()
  const end = noEnd.value ? null : validityTimeFrom.value[1].getTime()
  // очистить миллисекунды
  if ((start || noStart.value) && (end || noEnd.value)) {
    props.dateRanges.push([start, end])
    noStart.value = false
    noEnd.value = false
  } else {
    alert('Пожалуйста, укажите даты или отметьте соответствующие чекбоксы')
  }
}
function removeDateRange(index) {
  props.dateRanges.splice(index, 1)
}
</script>

<style lang="scss" scoped>
li {
  &:first-of-type {
    margin-top: 5px;
  }
  button {
    margin-left: 10px;
  }
  margin-bottom: 5px;
}
.admin-form__datepicker {
  & .dp__arrow_top {
    display: none;
  }
}
</style>