<template>
  <div class="violations_modal-mask">
    <div class="violations_modal-wrapper">
      <div class="violations_modal-container">
        <div class="wrap-authorization-form">
          <h4 class="text-center">
            {{
              SERVICE === 'Doosan'
                ? 'Эксплуатация машин ДСТ'
                : 'Навыки вождения MAN'
            }}
          </h4>
          <p
            id="submit-info"
            class="info-text text-center"
          >
            {{ prevError }}
          </p>
          <form
            id="athorization-form"
            action="/auth"
            method="POST"
          >
            <div class="row">
              <div class="col-3 for-input-text">
                <label for="inputLogin">Логин:</label>
              </div>
              <div class="col-9">
                <input
                  type="text"
                  class="form-control"
                  name="login"
                  id="inputLogin"
                  disabled
                  :value="props.login"
                />
                <div class="info-text">
                  {{ infoLogin }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 for-input-text">
                <label for="inputPassword">Пароль:</label>
              </div>
              <div class="col-9">
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  id="inputPassword"
                  autocomplete="off"
                  v-model="pass"
                />
                <div class="info-text">
                  {{ infoPassword }}
                </div>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-12 text-center">
                <button
                  id="authorization-btn"
                  class="btn btn-primary config-btn-primary"
                  name="send"
                  @click.prevent="fetchUserByApi"
                >
                  Войти
                </button>
                <img
                  class="my-btn admin_panel ml-2 cursor-pointer"
                  style="
                    margin-bottom: 25px;
                    padding: 8px 40px;
                    width: 125px;
                    height: 43px;
                  "
                  id="logout"
                  src="/images/logout.svg"
                  alt=""
                  @click="emit('logout')"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';

const URL_POST_USER = `${
  process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
}Api/auth`;

const props = defineProps({
  error: {
    type: String,
    required: false,
    default: '',
  },
  login: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['successAuth', 'logout']);

const SERVICE = inject('SERVICE');

const pass = ref('');

const prevError = ref(props.error);
const infoLogin = ref('');
const infoPassword = ref('');

const fetchUserByApi = async () => {
  try {
    const fd = new FormData();

    prevError.value = 'Отправляю запрос...';

    fd.append('login', props.login);
    fd.append('password', pass.value);
    fd.append('send', 'Войти');

    const res = await fetch(URL_POST_USER, {
      method: 'post',
      body: fd,
    });

    const data = await res.json();

    if (!data.success) {
      prevError.value = '';
      infoLogin.value = data.infoLogin;
      infoPassword.value = data.infoPassword;
    } else {
      prevError.value = '';
      emit('successAuth', {
        success: true,
        login: data.login,
        role: data.role,
        token: data.accessToken,
        rights: data.senderRights,
        token1c: data.token1c,
        token1c_time: Number(data.token1c_time) * 1000,
        templateTimeIntervals: data.templateTimeIntervals,
      });
    }
  } catch (err) {
    console.error(err);
  }
};
</script>

<style lang="scss" scoped>
.violations_modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.violations_modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.violations_modal-container {
  cursor: default;
  width: 95%;
  height: 95%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 20px 15px;
  }
}

h4 {
  margin-top: 10vh;
}
.info-text {
  line-height: 1em;
  margin-bottom: 15px;
  /* vertical-align:top; */
}
.wrap-authorization-form {
  width: 300px;
  /* height: 443px; */
  margin: 0 auto;
}
button {
  padding: 8px 40px;
  margin-bottom: 25px;
}
input[type='text'],
input[type='password'] {
  width: 100%;
}
.info-text {
  color: red;
  margin-top: 8px;
  margin-bottom: 24px;
  /* min-height: 16px; */
}
.for-input-text {
  padding-top: 7px;
}
.browsers-info {
  width: 70%;
  min-width: 300px;
  margin: 0 auto;
  border: 1px solid #c0c0c0;
  padding: 5px;
  color: #c0c0c0;
  font-size: 0.8em;
  margin-top: 10px;
}
.browsers-info ul {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
</style>
